// Notification.js
import React from "react";
import toast, { Toaster } from "react-hot-toast";

// Define the Notification component
const Notification = () => {
  return <Toaster position="top-right" reverseOrder={false} />;
};

// Helper function to trigger notifications
export const notify = (message, type = "success") => {
  switch (type) {
    case "success":
      toast.success(message);
      break;
    case "error":
      toast.error(message);
      break;
    case "info":
      toast(message);
      break;
    case "warning":
      toast(message, { icon: "⚠️" });
      break;
    default:
      toast(message);
  }
};

export default Notification;
