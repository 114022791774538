import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AdminProtectedRoute from "./auth/AdminProtect.js";
import ManagerProtectedRoute from "./auth/ManagerProtect.js";
import UserProtectedRoute from "./auth/UserProtect.js";
// import { SignIn, SignUp } from "@/pages/auth";

const SignIn = lazy(() => import("../pages/auth/SignIn.jsx"));
const SignUp = lazy(() => import("../pages/auth/SignUp.jsx"));
const ForgotPass = lazy(() => import("../pages/auth/ForgotPass.jsx"));

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/

/*****Pages******/
const Dashboard1 = lazy(() => import("../views/dashboards/Dashboard1.js"));
const Timesheets = lazy(() => import("../views/timesheets/Timesheets.js"));
const Expenses = lazy(() => import("../views/expenses/Expenses.js"));
const ManageUsers = lazy(() => import("../views/users/ManageUsers"));
const AddUsers = lazy(() => import("../views/users/AddUsers.js"));
const Team = lazy(() => import("../views/users/Team.js"));
const Project = lazy(() => import("../views/project/Project.js"));
const Task = lazy(() => import("../views/task/Task.js"));
const Classes = lazy(() => import("../views/classes/Classes.js"));
const Assigns = lazy(() => import("../views/assigns/Assigns.js"));
const TimeOff = lazy(() => import("../views/settings/TimeOff.js"));
const Holiday = lazy(() => import("../views/settings/Holiday.js"));
const ChartOfAccounts = lazy(() => import("../views/settings/ChartOfAccounts.js"));
const TimesheetSettings = lazy(() => import("../views/settings/TimesheetSettings.js"));
const ExpenseSettings = lazy(() => import("../views/settings/ExpenseSettings.js"));
const MyExpense = lazy(() => import("../views/myExpense/MyExpense.js"));
const TimesheetReport = lazy(() => import("../views/report/TimesheetReport.js"));
const ExpenseReport = lazy(() => import("../views/report/ExpenseReport.js"));
const TimeOffReport = lazy(() => import("../views/report/TimeOffReport.js"));
const DailyTime = lazy(() => import("../views/myTime/dailyView/DailyTime.js"));
const WeeklyView = lazy(() => import("../views/myTime/weeklyView/WeeklyView.js"));
const CalendarView = lazy(() => import("../views/myTime/calendarView/CalendarView.js"));
const WhoIsWroking = lazy(() => import("../views/whoIsWroking/WhoIsWroking.js"));
const SetUpAccount = lazy(() => import("../views/setUpAccount/SetUpAccount.js"));
const Quickbooks = lazy(() => import("../views/integrations/Quickbooks.js"));
const Asana = lazy(() => import("../views/integrations/Asana.js"));
const Bamboohr = lazy(() => import("../views/integrations/Bamboohr.js"));
const Xero = lazy(() => import("../views/integrations/Xero.js"));
const Zoho = lazy(() => import("../views/integrations/Zoho.js"));
const Sage = lazy(() => import("../views/integrations/Sage.js"));
const Hubspot = lazy(() => import("../views/integrations/Hubspot.js"));

/*****Tables******/
const BasicTable = lazy(() => import("../views/tables/BasicTable.js"));

// form elements
const ExAutoComplete = lazy(() =>
  import("../views/FormElements/ExAutoComplete.js")
);
const ExButton = lazy(() => import("../views/FormElements/ExButton.js"));
const ExCheckbox = lazy(() => import("../views/FormElements/ExCheckbox.js"));
const ExRadio = lazy(() => import("../views/FormElements/ExRadio.js"));
const ExSlider = lazy(() => import("../views/FormElements/ExSlider.js"));
const ExSwitch = lazy(() => import("../views/FormElements/ExSwitch.js"));
// form layouts
const FormLayouts = lazy(() => import("../views/FormLayouts/FormLayouts.js"));

/*****Routes******/


const pageRoutes = [
  {
    path: "/",
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "/login", element: <SignIn />, withoutFallback: true  },
      { path: "/signup", element: <SignUp />, withoutFallback: true  },
      { path: "/forgot-password", element: <ForgotPass />, withoutFallback: true  },
    ],
  },
];

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/dashboard", exact: true, element: <AdminProtectedRoute element={Dashboard1} /> },
      { path: "/my-expense", exact: true, element: <UserProtectedRoute element={MyExpense} /> },
      { path: "/timesheets", element: <AdminProtectedRoute element={Timesheets} /> },
      { path: "/expenses", element: <AdminProtectedRoute element={Expenses} /> },
      { path: "/users/manage-users", element: <AdminProtectedRoute element={ManageUsers} /> },
      { path: "/users/add-users", element: <AdminProtectedRoute element={AddUsers} /> },
      { path: "/users/manage-team", element: <AdminProtectedRoute element={Team} /> },
      { path: "/project/manage-project", element: <AdminProtectedRoute element={Project} /> },
      { path: "/project/manage-task", element: <AdminProtectedRoute element={Task} /> },
      { path: "/project/manage-classes", element: <AdminProtectedRoute element={Classes} /> },
      { path: "/project/manage-assigns", element: <AdminProtectedRoute element={Assigns} /> },
      { path: "/settings/manage-time-off", element: <AdminProtectedRoute element={TimeOff} /> },
      { path: "/settings/manage-holiday", element: <AdminProtectedRoute element={Holiday} /> },
      { path: "/settings/manage-chart-of-accounts", element: <AdminProtectedRoute element={ChartOfAccounts} /> },
      { path: "/settings/timesheet", element: <AdminProtectedRoute element={TimesheetSettings} /> },
      { path: "/settings/expense", element: <AdminProtectedRoute element={ExpenseSettings} /> },
      { path: "/setup-account", element: <AdminProtectedRoute element={SetUpAccount} /> },
      { path: "/report/timesheet-report", element: <ManagerProtectedRoute element={TimesheetReport} /> },
      { path: "/report/expense-report", element: <ManagerProtectedRoute element={ExpenseReport} /> },
      { path: "/report/time-off-report", element: <ManagerProtectedRoute element={TimeOffReport} /> },
      { path: "/time-entry/daily", element: <UserProtectedRoute element={DailyTime} /> },
      { path: "/time-entry/weekly", element: <UserProtectedRoute element={WeeklyView} /> },
      { path: "/time-entry/calendar", element: <UserProtectedRoute element={CalendarView} /> },
      { path: "/who-is-working", element: <AdminProtectedRoute element={WhoIsWroking} /> },
      { path: "/integration-QuickBooks", element: <AdminProtectedRoute element={Quickbooks} /> },
      { path: "/integration-Asana", element: <AdminProtectedRoute element={Asana} /> },
      { path: "/integration-BambooHR", element: <AdminProtectedRoute element={Bamboohr} /> },
      { path: "/integration-Xero", element: <AdminProtectedRoute element={Xero} /> },
      { path: "/integration-Zoho", element: <AdminProtectedRoute element={Zoho} /> },
      { path: "/integration-HubSpot", element: <AdminProtectedRoute element={Hubspot} /> },
      { path: "/integration-Sage", element: <AdminProtectedRoute element={Sage} /> },
      { path: "/tables/basic-table", element: <BasicTable /> },
      { path: "/form-layouts/form-layouts", element: <FormLayouts /> },
      { path: "/form-elements/autocomplete", element: <ExAutoComplete /> },
      { path: "/form-elements/button", element: <ExButton /> },
      { path: "/form-elements/checkbox", element: <ExCheckbox /> },
      { path: "/form-elements/radio", element: <ExRadio /> },
      { path: "/form-elements/slider", element: <ExSlider /> },
      { path: "/form-elements/switch", element: <ExSwitch /> },
    ],
  },
];



export { ThemeRoutes, pageRoutes };
