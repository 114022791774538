import React, { Suspense } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from './assets/global/Theme-variable';
import { ThemeRoutes, pageRoutes } from "./routes/Router";
import "./App.css";
import "./utilitycss/utility.css";
import Notification from "./assets/notification/Notification";
import { lazy } from "react";
const FullLayout = lazy(() => import("./layouts/FullLayout/FullLayout.js"));

const App = () => {
  // Combine both route arrays
  const routes = [...pageRoutes, ...ThemeRoutes];

  const routing = useRoutes(routes);

  const location = useLocation();
  const { pathname } = location;

  const getFallback = () => {
    if (pathname === "/login" || pathname === "/signup" || pathname === "/forgot-password" || pathname === "/") {
      return <div></div>;
    }
    return <FullLayout/>;
  };

  const theme = baseTheme;

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={getFallback()}>
        {routing}
      </Suspense>
      <Notification />
    </ThemeProvider>
  );
};

export default App;
