import React, { createContext, useEffect, useState } from 'react';
import axios from "axios";
const MyContext = createContext();

const MyProvider = ({ children }) => {

  const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));


  if (adminloginInfo) {
    var companyID = adminloginInfo?._id;
    var token = adminloginInfo?.token
  } else if (userLoginInfo) {
    var companyID = userLoginInfo?.companyID;
    var token = userLoginInfo?.token
  }

  const config = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };

  const [loading, setLoading] = useState(false)
  const [showDemoAlert, setShowDemoAlert] = useState(false)
  const [reportFilteredData, setReportFilteredData] = useState();
  const [expenseReportFilteredData, setExpenseReportFilteredData] = useState();
  const [sendPass, setSendPass] = useState(false)
  const [sendWelcome, setSendWelcome] = useState(true)
  const [activeItem, setActiveItem] = useState(null);
  const [activTab, setActivTab] = useState("user");
  const [newUser, setNewUser] = useState();
  const [selectedPropertiesForTimesheet, setSelectedPropertiesForTimesheet] = useState({
    Date: true,
    Task: true,
    Project: true,
    Hours: true,
    Status: true,
    Employee: true,
    Billable: true,
    Description: true,
    Location: true
  });
  const [selectedPropertiesForTimeOff, setSelectedPropertiesForTimeOff] = useState({
    Date: true,
    Task: true,
    Balance: true,
    Hours: true,
    Status: true,
    Employee: true
  });
  const [selectedPropertiesForExpense, setSelectedPropertiesForExpense] = useState({
    Date: true,
    Expense: true,
    Project: true,
    Amount: true,
    Status: true,
    Employee: true,
    Billable: true,
    Description: true,
    Location: true
  });
  const [loadingTimesheet, setLoadingTimesheet] = useState(false)
  const [show, setShow] = useState(false)

  const [additionalSettings, setAdditionalSettings] = useState({
    timer: false,
    clockIn: false,
    timesheetApprover: false,
    timesheetClasses: false,
    expenseApprover: false,
    receipt: false
  })

  const getSettings = async () => {
    try {
      const { data } = await axios.get(`https://v2.timetraker.com/backend/api/get-settings/?companyID=${companyID}`, config)
      if (data) {
        setAdditionalSettings({
          timer: data.settings.timesheetSettings.timer,
          clockIn: data.settings.timesheetSettings.clockIn,
          timesheetApprover: data.settings.timesheetSettings.approver,
          timesheetClasses: data.settings.timesheetSettings.classes,
          expenseApprover: data.settings.expenseSettings.approver,
          receipt: data.settings.expenseSettings.receipt
        })
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    getSettings()
  }, [])
  return (
    <MyContext.Provider value={{ showDemoAlert, setShowDemoAlert, additionalSettings, setAdditionalSettings, newUser, setNewUser, activTab, setActivTab, selectedPropertiesForTimeOff, setSelectedPropertiesForTimeOff, show, setShow, loadingTimesheet, setLoadingTimesheet, setReportFilteredData, reportFilteredData, setExpenseReportFilteredData, expenseReportFilteredData, loading, setLoading, setSendPass, sendPass, sendWelcome, setSendWelcome, selectedPropertiesForTimesheet, setSelectedPropertiesForTimesheet, selectedPropertiesForExpense, setSelectedPropertiesForExpense, activeItem, setActiveItem }}>
      {children}
    </MyContext.Provider>
  );
};

export { MyProvider, MyContext };
