import React from "react";
import { Navigate } from "react-router-dom";

const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
const isAuthenticated = adminloginInfo?.token ? true : userLoginInfo?.token ? true : false;


const UserProtectedRoute = ({ element: Component, ...rest }) => {
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default UserProtectedRoute;
