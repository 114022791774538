import React from "react";
import { Navigate } from "react-router-dom";

const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
const isAuthenticated = adminloginInfo?.token ? true : false;


const AdminProtectedRoute = ({ element: Component, ...rest }) => {
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default AdminProtectedRoute;
